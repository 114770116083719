/* You can add global styles to this file, and also import other style files */

@import "/src/app/shared/styles/app/variables";

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~weather-icons/css/weather-icons.css';
@import '~weather-icons/css/weather-icons-wind.css';

@import '~spinkit/css/spinkit.css';
@import '~loaders.css/loaders.css';

@import '../node_modules/ngx-toastr/toastr.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';

@import '~codemirror/lib/codemirror.css';

@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";

//== NgSelect
@import "~@ng-select/ng-select/themes/default.theme.css";

//== Application
@import "./app/shared/styles/app.scss";

.cursor-pointer {
  cursor: pointer;
}

.preserve-new-line {
  white-space: pre-line;
}

/* FONT AWESOME GENERIC BEAT */ 
.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
}